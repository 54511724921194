.fc-day:hover{background:rgb(234, 241, 243);cursor: pointer;}
.fc-daygrid, .fc-timegrid {
    background-color: white;
}
.fc-header-toolbar > div:first-child > div{
    display: flex;
}
.fc-header-toolbar > div:first-child button{
    margin-left:10px;
    height: 40px;
    border-radius: 50%;
}
.editAppointment, .searchForAppointment{
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.editAppointment > div, .searchForAppointment > div{
    background-color: white;
    padding:20px;
    overflow-y: auto;
    border-radius: 10px;
    max-height: 85vh;
    width:435px
}
.editAppointment .close, .searchForAppointment .close{
    position: absolute;
    top:10px;
    right:10px;
    z-index: 10;
    cursor: pointer;
    font-size: 30px;
    background-color: white;
    border:2px solid white;
    border-radius: 50%;
}
.editAppointment h2{
    margin:0
}
.editAppointment > div > div{
    margin-bottom: 20px;
}
.editAppointment .title{
    font-weight: 600;
    font-size:13px
}
.editAppointment .timeRowContainer {
    display: inline-block;
}
.editAppointment .timeRowContainer > div{
    min-width: 100px;
}
.datepickercontainer{
    width:150px;
    display: inline-block;
}
.schedulecontainer{
    display: flex;
    align-items:center;
    justify-content: space-between;
}
.schedulecontainer .timeRowContainer{
    margin-bottom: 0;
}
.userOnboarding.calendarView{
    height:100vh;
    display: flex;
    flex-direction: column;
}
.calendarView .whiteBox{
    overflow-y: auto;
}
.poCheckoutBox{
    -webkit-box-shadow: 0px 0px 23px -14px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 23px -14px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 23px -14px rgba(0,0,0,0.75);
    padding:30px;
    border-radius: 10px;
    width:30vw;
    height:100%
}
.patientBookingList > div{
    border-bottom: 1px solid grey;
    padding:20px 10px;
    cursor: pointer;
}
.patientBookingList i{
    margin-right: 15px;
    color:var(--blue)
}
.patientBookingList .title{
    font-weight: 700;
}
.patientBookingList .startTime{
    color:grey;
    font-size:14px;
}
.poCheckoutBox h2{
    margin-top:0
}
.calendarView .flexgrow{
    overflow-y: auto;
}
.calendarView h1{
    margin:0;
    margin-top:10px
}
.poServiceList h2{
    margin:0
}
.poServiceList {
    margin-bottom: 25px;
    padding-bottom: 25px;
    cursor:pointer;
    border-bottom:1px solid rgb(218, 218, 218)
}
.poServiceList > div:nth-child(2){
    flex-grow:1
}
.poServiceList .price{
    font-size:20px;
    font-weight: 700;
    min-width: 100px;
    text-align: center;
}
.poServiceList .mins{
    font-size: 13px;
    color:grey;
    margin-bottom: 15px;
}
.poCheckoutBox h2{
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(224, 224, 224);
}
.poCheckoutBox .price{
    margin-left:20px
}
.poCheckoutBox .mins{
    color:grey
}
.poCheckoutBox .info{
    background-color: rgb(231, 238, 255);
    padding:10px;
    font-size: 12px;
    border-radius: 5px;
    margin-top:30px
}
.poDateSelection{
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 25px;
}
.poDateSelection > div{
    display: inline-block;
    border:1px solid #dddddd;
    border-radius: 5px;
    padding:10px 20px;
    margin-right: 15px;
    cursor:pointer;
    text-align: center;
    min-width: 90px;
}
.poDateSelection .month{
    position: absolute;
    right:0;
    bottom:0;
    color:var(--blue);
    font-size:11px;
    padding:5px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
}
.poDateSelection .day{
    color:grey
}
.poDateSelection .disabled{
    opacity: 1;
    background-color: #f7f7f7;
    border-color:#f7f7f7
}
.POHeader .back{
    margin-right: 10px;
    cursor:pointer
}
.poDateSelection .selected{
    background-color: var(--blue);
    color:white
}
.poDateSelection .selected .day, .poDateSelection .selected .month{
    color:white !important
}
.poTimeSelection > div{
    padding:20px;
    border-bottom: 1px solid rgb(221, 221, 221);
    cursor:pointer;
    font-weight: 600;
}
.flexeven > .poField:first-child{
    margin-right: 15px;
}
/* .poTimeSelection .selectedTime{
    background-color: var(--blue);
    color:white */
