@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root{
  --dark: #323541;
  --blue: #18B8FE;
  --background: #F3F7F7;
  --white: white;
  --layoutPadding: 25px;
  --small: 12px;
  --med: 15px;
  --large: 20px;
  --largest: 35px;
  --huge: 43px;
}
.blue{
  color:var(--blue)
}
.small{
  font-size: var(--small);
}
.med{
  font-size: var(--med);
}
.largest{
  font-size: var(--largest);
}
.huge{
  font-size:var(--huge)
}
body{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:var(--background);
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html{
  height: -webkit-fill-available;
}
div{
  box-sizing: border-box;
  position: relative;
}

.flex{
  display: flex;
}
.flexgrow{
  flex-grow: 1;
}
.menu{
  background-color: var(--dark);
  display: inline-block;
  height: 100vh;
  max-width:240px;
}
.menu a{
  color:white;
  text-decoration: none;
  font-weight: 600;
}
.menu i{
  margin-right:15px
}
.contentContainer{
  height:100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.contentContainer .mainContent{
  flex-grow: 1;
  overflow-y:auto;
  margin-top: 20px;
}
.grafoButton{
  color:white !important;
  text-transform: none !important;
  font-weight: 600 !important;
  padding-top:10px !important;
  padding-bottom: 10px !important;
  white-space: nowrap;
  font-size: var(--small) !important;
}
.grafoButton.transparent{
  background-color: white !important;
  border:2px solid var(--blue) !important;
  color:var(--blue) !important
}
.outerContainer{
  overflow: hidden;
  height:100vh
}
.loadingContainer{
  height:100vh;
  width:100vw;
  align-items: center;
  justify-content: center;
}
.flex{
  display: flex;
}
.flexcenter{
  display: flex;
  align-items: center;
}
.flexeven{
  display: flex;
}
.flexeven > div{
  flex-grow: 1;
  flex-basis: 0;
  flex: 1;
}
.flexmiddle{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.flexbetween{
  display: flex;
  justify-content: space-between;
}
.uForm{
  width: 350px;
}
.uForm .description{
  color:grey;
  font-size: var(--small);
  text-align: center;
}
.formInputContainer{
  margin-bottom:15px;
  width:100%
}
.unauthenticatedContainer .artwork{
 background-image: url(https://i.ibb.co/j5LLmHZ/Group-28.png);
 padding:100px;
 color:var(--white);
 max-width: 50%;
}
.unauthenticatedContainer .artwork .bottom{
  position: absolute;
  bottom:0;
  padding:20px;
  left:0;
  width:100%;
  font-size: var(--small);
}
.unauthenticatedContainer .artwork .profiles{
  height:var(--largest);
  margin-right:15px
}

.coverimage{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.unauthenticatedContainer{
  height:100vh;
  background-color: white;
}

.uForm h2{
  text-align: center;
}
.paddingX{
  padding-left: var(--layoutPadding);
  padding-right:var(--layoutPadding);
}
.contentContainer > div{
  padding-left: var(--layoutPadding);
  padding-right:var(--layoutPadding);
}
.square:after, .circle:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.circle{
  border-radius: 50%;
}
.topMenuBar{
  box-shadow: 0px -0.5px 0px 0px #D5D5D5 inset;
  background-color: var(--white);
  padding-top:10px;
  padding-bottom: 10px;
  font-weight: 600;
}
.topMenuBar .circle{
  width:38px
}
.topMenuBar .coverImage{
  margin-right:7px
}
.topMenuBar .name{
  font-size:var(--small)
}

.coverImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.menu .css-gvdg5s-MuiButtonBase-root-MuiListItemButton-root.Mui-selected, .menu .css-gvdg5s-MuiButtonBase-root-MuiListItemButton-root.Mui-selected:hover{
  background-color: var(--blue);
}

div.menuButton{
  padding-left:30px;
  padding-right:50px;
}

.hoverRow:hover{
  background-color: #f8f9ff;
  cursor: pointer;
}
.buttonGroup > button:first-child{
  margin-right: 10px
}
.clientsContainer{
  display: flex;
  flex-direction: column;
}
.clientsContainer .flexgrow{
  overflow-y:auto
}
.sticky{
  position: sticky;
  top:10px
}
.clientsContainer > div{
  margin-top:20px
}
.grafoButton i{
  margin-right: 10px;
}
.statsBar{
  background-color: var(--dark);
  color:white;
  display:flex;
  padding:20px
}
.statsBar > .stat:not(:last-child){
  border-right:1px solid var(--blue)
}
.statsBar .stat{
  width:24%;
  padding-left:15px;
  padding-right:15px
}
.stat .percentage{
  font-size: var(--small);
}
.stat .number{
  font-size: var(--largest);
}
.stat .up.percentage{
  color:#3DB353
}
.stat .down.percentage{
  color:pink
}
.stat .title{
  font-size: var(--small);
}
.stat .description{
  color:#bebebe;
  font-size: var(--small);
  margin-top:20px
}
a{
  color:var(--blue);
  text-decoration: none;
  font-weight: 600;
}
.googleAuth{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border: 1px solid #bebebe !important;
}
.googleAuth div{
  margin-right: 0 !important;
}
.uForm h2{
  margin-bottom: 0;
  font-weight: 600;
}
.uForm .validation{
  color:red;
  font-size: var(--small);
}
.darkOverlay{
  position: fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 50;
}
.recommendation{
  color:grey;
  text-align:center;
  padding-top:25px;
  padding-bottom:25px
}
.clickable:hover{
  opacity:0.5;
}
.clickable{
  cursor:pointer
}
.viewPadding{
  padding-left:4%;
  padding-right:4%
}
.childViewPadding > div{
  padding-left:20px;
  padding-right:20px
}
.viewTabs{
  margin-left:-7px;
  font-size:var(--small);
  margin-top:20px;
  border-bottom: 1px solid #dedede;
}
input.noStyle{
  font-family: inherit;
  font-size: inherit;
  color:inherit;
  outline: none;
  border:0
}
.disabled{
  pointer-events: none;
  opacity:0.3
}
.formSection.dark{
  padding-left:var(--layoutPadding);
  padding-right:var(--layoutPadding)
}
.topMenuBar .profile:hover{
  background-color: #f3f3f3;
  border-radius:5px;
  cursor:pointer
}
.stripeBox{
  border:1px solid #dedede;
  background-color:white;
  border-radius: 10px;
  overflow: hidden;
  width:350px;
  color: black;
}
.stripeBox .title{
  font-weight: 600;
}
.stripeBox .subscriptionExpiredMessage{
  background-color: #ececec;
  padding:8px;
  border-radius:0px 0px 10px 10px;
  cursor: pointer;
  font-size:var(--small);
  margin-top:15px
}
.settingSection .title{
  font-weight: 600;
  font-size:var(--large);
  margin-bottom: 10px;
}
.settingSection .subTitle{
  font-weight: 600;;
}
.settingSection .date{
  color:var(--blue);
  font-size: var(--small);
}
.settingSection{
  padding:20px;
  border:1px solid rgb(238, 238, 238)
}
.stripeBox .square{
  background-image: url(https://i.postimg.cc/xCW9W175/Screenshot-2022-02-08-at-18-17-39.png);
  width:70px;
  border-radius: 7px;
}
.stripeBox .header{
  height:80px;
  background-image: url(https://i.ibb.co/x3vM9S8/Mask-Group.png);
}
.stripeBox .pad{

  padding:15px;
}
.stripeBox .logo{
  margin-top:-50px
}
.stripeBox .loading{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 50;
}
.backSignup {
  display: none;
}
.settingSection{
  padding:15x;
  background-color:white;
  margin-bottom:20px;
  border-radius: 10px;
}
.planBenefits i{
  color:var(--blue);
  margin-right: 10px;
}
.planBenefits{
  margin-top:30px
}
.userOnboarding{
  border-top:13px solid var(--blue);
}
.userOnboarding > div{
  padding:20px 50px;
  background-color: white;
  border-bottom: 1px solid #dedede;
}
.grafoTableStyle i{
  color:var(--blue)
}
.grafoTableStyle i.fa-check{
  color:green
}
.grafoTableStyle i.fa-times{
  color:red
}
.timeSchedule .month{
  text-transform: capitalize;
  min-width: 300px;
  width:300px
}
.timeSchedule.error .errorMessage{
  color:red;
  font-size:12px;
  text-transform: none;
  padding-left:10px;
  padding-right: 10px;
}
.timeSchedule .errorMessage{
  display: none;
}
.timeSchedule.error .errorMessage{
  display: block;
}
.timeSchedule{
  border-bottom: 1px solid rgb(189, 189, 189);
  padding-bottom: 20px;
  padding-top:20px
}
.currencySelection{
  position: absolute;
  top:0;
  right:0
}
.profileTabs{
  margin-bottom: 20px;
  margin-top:0
}
.currencyContainer{
  position: absolute;
  right:10px;
  top: -40px;
}
.currencyContainer > div:first-child{
  font-weight: 700;
}
.serviceListEntry{
  margin-bottom:50px;
  padding-bottom:50px;
  border-bottom: 1px solid rgb(197, 197, 197);
}
.timeSelectionSelect{
  min-width:110px
}
.timeSelectionIcon{
  font-size:20px;
  margin-left:10px;
  padding:10px;
  cursor:pointer
}
.timeRowContainer{
  margin-bottom: 20px;
}
.timeRowContainer .MuiOutlinedInput-root{
  min-width: 200px;
}
.timeRowContainer.errorTime{
  color:red;
}
.timeRowContainer.errorTime .MuiOutlinedInput-root {
  border:1px solid red
}
textarea{
  font-family: inherit;
  font-size:inherit;
  border:1px solid rgb(187, 187, 187);
  padding:10px;
  box-sizing:border-box;
  font-size:13px;
  border-radius: 5px;
  width:100% !important;
  min-height:60px
}
.paymentPlans .checkbox{
  text-align: center;
  margin-bottom: -10px;
}
.paymentPlans > div{
  width:300px;
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
}
.paymentPlans{
  margin-top: 30px;
}
.paymentPlans .tag{
  background-color: #E8F4FF;
  color:#00BDFF;
  font-size: 12px;
  padding:5px 10px;
  border-radius: 5px;
}
.paymentPlans hr{
  border:0;
  height:1px;
  background-color: rgb(224, 224, 224);
}

.paymentPlans > div.selected{
  border: 4px solid #00BDFF;
}
.paymentPlans .mainContent{
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.1);
border-radius: 10px;
padding: 30px 0px
}
.paymentPlans .mainTitle{
  color:grey;
}
.paymentPlans .mainContent > div{
  margin-bottom: 15px;
  padding:0px 30px
}
.paymentPlans .price{
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 0px !important;
}
.paymentPlans .price sup{
  font-size: 15px;
}
.paymentPlans .description{
  color:grey;
  font-size:12px
}
.paymentPlans .featuresContainer i{
  color:#2BD672;
  margin-right: 10px;
}
.paymentPlans .mainContent .featuresContainer{
  font-size:13px;
  margin-top:20px
}
.paymentPlans .featuresContainer > div{
  margin-bottom: 10px;
}