/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .grafoButton.submitNew{
        width:100%;
        font-size:16px !important;
        padding:15px !important
      }
  
      div.userOnboarding .poBookNow{
          position: fixed;
          bottom:0;
          width:100%;
      }
    div.userOnboarding > div{
        padding: 10px 20px
    }
    div.mainContent{
        padding-bottom:80px
    }
    .fc h2.fc-toolbar-title{
        font-size: 16px;
        margin-top:6px
    }
    .unauthenticatedContainer .artwork{
        display: none;
    }
    .unauthenticatedContainer .flexmiddle{
        padding:20px
    }
    .uForm{
        width: 90%;
    }
    div.clientsContainer{
        padding: 15px;
    }
    div.statsBar{
        display: block;
        margin-top:0
    }

    div.poCheckoutBox{
        display: none;
    }
    div.calendarView > .flex{
        display: block;
    }
    div.bodyMapContainer{
        display: block;
        text-align: center;
    }
    div.bodyMapContainer > div{
        text-align: left;
    }
    div.bodyMapContainer .dndflow {
        display: inline-flex;
    }
    div.statsBar > div{
        display: inline-block;
        width:120px !important;
        margin-right:15px
    }
    i.mobileMenuClick{
        display:inline-block
    }
    div.topMenuBar{
        font-size: 26px;
    }
    div.topMenuBar .right span{
        display: none;
    }
    div.innerBodyMap{
        margin-top:20px !important
    }
    button.MuiButton-contained{
        font-size: 16px !important
    }
    div.contentContainer .mainContent{
        padding:10px
    }
    div.settingSection .title{
        margin-bottom: 10px;
    }
    div.settingSection .MuiFormControl-root{
        display: block;
        width:100%
    }
    div.settingSection .flexeven, div.settingSection .flex{
        display: block;
    }
    div.header > div:first-child{
        font-size: 22px;
    }
    div.clientsContainer .buttonGroup button{
        margin-right: 0;
    }
    .mobileArrow{
        color:white; 
        position:absolute;
        font-size:25px;
        top:0;
        right:0; 
        padding:15px;
        display: block !important
    }
    div.listContainer > div{
        display: none;
    }
    div.listContainer > div.selected{
        display: flex;
    }
    div.listContainer > div.selected i{
        transform: rotate(90deg);
        margin-right: 15px;
    }
    div.listContainer.showList > div{
        display: flex !important;
    }
    div.formSelections{
        width: 100%; display: block
    }
    
    .settingSection .MuiOutlinedInput-root{
        width:100%
    }
    div.settingSection .buttonRight{
        position: relative;
        width:100%;
        display: block !important;
        right:0
    }
    div div.profileInputMarginRight {
        margin-right:0px
    }
    div div.includedInPlan{
        margin-left: 0px;
        margin-top:30px;
        text-align: center;
    }
    div.stripeBox{
        width: 100%;
    }
    div.notesListMain{
        display: block;
    }
    div.notesListMain .listContainer{
        margin-right: 0;
        display: flex;
        flex-direction: column-reverse;
    }
    div.notesListMain .listContainer > div:first-child{
        margin-top:30px
    }

    .menu{

  display: none;
  position: fixed !important;
  top:0;
  left:0;
  height:100vh;
  width:100vw !important;
  z-index: 5;
  max-width: none !important
    }
    .menu > div:first-child {
        text-align: center;
    }
    .menu .MuiListItemButton-root, .menu > div:last-child{
        text-align: center;
    }
    .menu .MuiTypography-root, .menu > div:last-child{
        font-size: 25px;
        padding: 20px 5px
        
    }
    .menu > div:last-child{
        display: block;
    }
    .menu.showMobMenu{
        display: block;
    }
    div.mainContent{
        padding:0;
    }
    .formSection .flexeven{
        display: block;
    }
    .header .flexcenter{
        display: block;
    }
    div.formSection .formInputContainer, .div.formSection > div{
        margin-bottom: 30px;
    }
    div.header .title{
        margin-bottom: 25px;
    }
    div.header{
        background: var(--background) !important
    }
    div.assessmentDescription.flex{
        display: block;
    }
    div.assessmentDescription .ratingBox{
        margin:0;
        margin-top:10px
    }
    div.genericAssessmentField{
        margin-top:5px
    }
    div.genericAssessmentField .MuiCheckbox-root{
        padding-right: 3px;
    }
    div.remove{
        top:-5px;
        right:0;
        position: absolute
    }
    div.remove i{
        font-size: 12px;
    }
    div.bodyMapContainer .innerBodyMap{
        margin:0
    }
    div.assessmentBox{
        display: block;
        margin-bottom: 10px;
    }
    div.assessmentBox.new{
        height: auto;
        min-height: auto;
        padding:20px;
        margin-bottom: 10px;
    }
    div.assessmentBoxContainer{
        height:auto
    }
    div.clientView .header{
        z-index: 50;
    }
    
    
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 1114px) {
    div.assessmentDescription{
        display: block;
    }
    div.innerBodyMap{
        margin-left: 30px;
    }
    div.unauthenticatedContainer .artwork{
        display: none
    }
    i.backSignup {
        position: absolute;
        top:3%;
        left:5%;
        font-size:22px;
        display: block;
    }
    div.clientView{
        height: 100%;
        min-height: 100%;
    }
    div.viewPadding{
        padding-left:2%;
        padding-right: 2%;
    }
    div.bodyMapContainer .innerBodyMap {
        margin-left:10px
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

 }
