
.clientView .header{
    background-color: var(--white);
    padding-top:20px;
    font-weight: 600;
    position: sticky;
    top:0;
    z-index: 10;
}
.clientView{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    min-height:100%;
    overflow-y: auto;
    background-color:var(--background);
}
.clientView .content{
    background-color: var(--background)
}
.clientView .header .title{
    font-size:var(--large)
}
.formSection > .flexeven > div:first-child {
    margin-right:20px
}
.formSection > div{
    margin-bottom:20px
}
.clientView .content{
    padding-top:40px
}
.formSection{
    padding-bottom: 40px;
}
.formSection.dark{
    background-color: var(--dark);
    color:var(--white);
    padding-top:60px;
    padding-bottom:60px
}
.formSection.dark, .formSection.dark .MuiOutlinedInput-root, .formSection.dark input, .formSection.dark label {
    color: var(--white) !important;
    border-color:var(--white) !important
}
.formSection.dark fieldset{
    border-color:var(--blue)
}

input[value=""]::-webkit-datetime-edit{ color: transparent; }
input:focus::-webkit-datetime-edit{ color: #000; }

.dragRow{
    background-color: white;
    padding:25px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    border:1px solid #dedede;
    border-radius: 10px;
}
.dragRow input{
    width: 90%;
}
.dragHandle{
    background-color: var(--blue);
    color:var(--white);
    padding:10px;
    border-radius: 10px;
    margin-right:10px;
    cursor:move
}
.dragRow .select > div{
    font-size:var(--small);
    padding: 5px 30px 5px 10px !important
}
.dragRow .select{
    margin-top:5px
}
.dragRow{font-size:var(--small)}
.dragRow .fa-trash-alt{
    background-color: red;
    color:var(--white);
}
.dragRow .fa-copy{
    background-color:#588d58;
    color:var(--white);
    margin-right: 5px;
}
.dragRow .actions i{
    padding:10px;
    border-radius: 5px;

    cursor:pointer
}
.addBox{
    color:var(--blue);
    font-weight: 600;
    text-align: center;
    font-size: var(--small);
    padding:20px;
    background-color: white;
    border: 2px dashed var(--blue);
    cursor: pointer;
}
.manageAssessment .title{
    font-weight: 600;
}
.colorCircles > div{
    width:30px;
    height:30px;
    border-radius: 50%;
    cursor:grab
}
.assessmentChangeButton{
    border:1px solid #bebebe;
    text-align: center;
    padding:10px;
    font-size: var(--small);
    display: flex;
    border-radius: 10px;
    min-width:200px
  }
  .assessmentChangeButton i{
      cursor: pointer;
      padding:5px
  }
  .assessmentChangeButton i:hover{
      opacity: 0.5;
  }
  .colorCircles{
    border: 1px solid #dedede;
    text-align: center;
    max-width: 55px;
    padding:10px;
  }
  .colorCircles > div{
      width:30px;
      height:30px;
      margin-bottom:10px;
      display: inline-block
  }
.bodyImg{
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    height:380px;
    transform: translate(-50%, -50%);
  }
  .mia{
        position: absolute;
        top: 50%;  /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */
        transform: translate(-50%, -50%);

  }
  .assessmentDescription .title{
    font-weight: 600;
    font-size:11px
  }
  .assessmentDescription .painType{
      font-weight: 600;
      font-size:17px;
      color:black
  }
  .assessmentDescription .color{
      width:40px;
      height:40px;
      margin-right:15px
  }
  .painAssessment > div:not(:last-child){
      margin-right:20px
  }
  .painAssessment{
      margin-top:20px
  }
  .assessmentDescription {
      margin-bottom: 25px;
      border:1px solid #bebebe;
      padding:15px;
      border-radius:10px
  }
  .assessmentDescription .remove{
      color:red;
      font-size: var(--large);
      cursor: pointer;
  }
  .assessmentDescription i{
    border-radius: 10px;
    border:1px solid #bebebe;
    padding:10px;
    margin-top:0px
  }
  .adFields > div > div:first-child{
      width:70%
  }
  .adFields > div > div{
      width:25%;
      margin-right:3%
  }
  .adFields .title{
      font-weight: 600;
      font-size: var(--small);
  }
  .adFields > div:first-child{
      margin-bottom:20px
  }
.draggableCircle{
    width:30px;
    height:30px;
    border-radius: 50%;
    cursor:grab;
    display: inline-block;
}
.circleSelector .draggableCircle{
  
    -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.circleSelector{
    text-align: center;
}
.circleSelector > div:active{
    box-shadow: unset;
}
.circleSelector > div{
    margin-bottom:17px;
}

.circleName{
    font-size: 11px;
}


.circleSelector{
    margin-right:15px;
    padding:10px;
    border: 1px solid #bebebe;
    border-radius: 10px;
}

.changeMapControls{
    border:1px solid grey;
    text-align: center;
    border-radius: 15px;
}
.changeMapControls i{
    padding:10px;
    cursor: pointer;
}
.changeMapControls.compressed .flexgrow{
    display: none;
}
.changeMapControls.compressed > div:first-child, .changeMapControls.compressed > div:last-child{
    background-color:#dedede;
    border-radius: 50%;
    display: inline-block;
}
.changeMapControls.compressed > div:first-child:hover, .changeMapControls.compressed > div:last-child:hover{
    opacity:0.5
}
.changeMapControls.compressed > div:first-child{
    margin-right:80px
}
.changeMapControls.compressed{
    border:0;
    text-align: center;
    width:100%;
    display: block;
    margin-top:-70px;
    margin-bottom:40px;
    z-index: 20;
}
.assessmentEditNotes{
    padding:20px;
    border-radius: 10px;
    font-family: inherit;
    margin-bottom:20px;
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.assessmentBox .title{
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    margin-top:10px;
    font-size: var(--small);
}
.assessmentBox .date{
    color:var(--blue);
    font-weight: 600;
    font-size:var(--small);
}
.assessmentBox{
    display: inline-block;
    border:2px solid #dedede;
    border-radius: 10px;
    width:270px;
    height:100%;
    padding:10px;
}
.scrollbarmassage::-webkit-scrollbar {
  -webkit-appearance: none;
  height:12px

}
.scrollbarmessage::-webkit-scrollbar-track{
    background: yellow;
}
.scrollbarmassage::-webkit-scrollbar-thumb {
  border-radius: 4px;
  height:3px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.assessmentBox:hover{
    border-color:var(--blue);
    cursor: pointer;
}
.bodymapcontainer{
    text-align:center
}
.bodymapcontainer > div{
    display: inline-block;
}
.assessmentBox .notes{
    background-color: #D8F3FF;
    padding:10px;
    min-height: 80px;
    height:80px;
    border-radius: 10px;
    font-size: var(--small);
    word-wrap: break-word;
    white-space: initial;
}
.assessmentBox .notes p{
    margin-top:0
}
.assessmentBox .editShow{
    color:var(--blue);
    position:absolute;
    top:10px;
    right:10px;
    padding:10px;
    border-radius: 5px;
    border:1px solid var(--blue);
}
.listContainer .selected i:not(.nostyle){
    color:white !important;
    background-color: var(--blue);
    
}
.listContainer i{
    padding:5px 8px;
    border-radius: 50%;
}
.assessmentBox .editShow:hover{
    background-color: var(--blue);
    color:white
}
.assessmentBox{
    vertical-align: top;
    margin-right:15px
}
.assessmentBoxContainer{
    width:100%;
    overflow-x:auto;
    white-space: nowrap;
    height: 565px;
}
.assessmentBox.new{
    height:100%;
    min-height: 100%;
    border: 2px dashed var(--blue);
    text-align: center;
    color:var(--blue);
    font-weight: 600;
}
.assessmentBox.new > div{
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
}
.form
.formEditorQuestion .label{
    font-weight: 600;
    font-size: var(--med);
    margin-bottom:10px;
}
.formEditorQuestion{
    padding:20px;
    background-color: #f1f1f1;
    margin-top:20px;
}
.formEditorQuestion input{
    background-color: white;
}
div > .formEditorQuestion:not(:last-child){
    border-bottom: 1px solid rgba(244, 244, 244, 1);
}
div > .formEditorQuestion.section{
    background-color: white;
    font-weight: bold;
    padding-left: 0;
}
.formEditorQuestion.section .label{
    margin-top:40px;
    font-size:23px
}

.addForm{
    border: 2px dashed var(--blue);
    padding:10px;
    color:var(--blue);
    cursor:pointer;
    font-weight: 600;
}
.addForm:hover{
    opacity:0.6
}
.formViewer .title{
    font-weight: 600;
    font-size: var(--large);
    margin-bottom:10px;
    cursor: pointer;
}
.formViewer .qTitle{
    font-weight: 600;
    color:var(--blue)
}
.formQSection{
    padding-bottom:30px;
    padding-top:30px
}
.createAssessment.clientView{
    z-index: 500;
}
.flexcolumn{
    display: flex;
    flex-direction: column;
}
.createAssessment .bottomWhite{
    background-color:white;
    padding-top:15px;
    padding-bottom: 15px;
    border-top:1px solid #dedede
}
.createAssessment .bottomWhite .flexgrow{
    padding-left:20px;
    padding-right: 30px;
}
.imageList > div{
    width:100px;
    height:100px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-right:20px;
    border:2px dashed var(--blue);
    border-radius: 5px;
    cursor:pointer
}
.imageList{
    overflow-x:auto;
    white-space: nowrap;
}
.imageList .fa-trash-alt{
    background-color:red;
    color:white;
    padding:5px;
    border-radius:5px;
}
.formSection .validation{
    color:red;
    font-size:var(--small)
}
.formSection .title{
    font-weight: 600;
    font-size:18px
}
.individualNote .date{
    background-color:var(--blue);
    font-size: var(--med);
    font-weight: 600;
    color:white;
    padding:10px 15px;
    font-style: italic;
    display: inline-block;
    border-radius: 5px;
}
.individualNote .edit{
    background-color: #dedede;
    padding:10px;
    font-size: var(--small);
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top:10px;
    right:10px
}

.mobileMenuClick{
    display: none;
    margin-right:10px;
}
.individualNote{
    border:2px solid #dedede;
    padding-top:20px;
    padding-left:20px;
    padding-right:20px;
    padding-bottom: 40px;
}
.contentNote{
    height: 100px;
    overflow: hidden;
}
.notesAttachments {
    overflow-x:auto;
    white-space: nowrap;
    margin-top:20px
}
.notesAttachments > div{
    display: inline-block;
    vertical-align: middle;
    width:100px;
    border-radius: 5px;
    border: 5px solid var(--blue);
    cursor:pointer
}
.addnotecontainer .quill{
    background-color: white;
}

.settingSection .buttonRight{
    position: absolute; top: 8px; right: 25px ; color:white; font-weight: 600;
}
.bodyMapContainer .flexgrow{
    margin:0
}
.mobileArrow{
    display: none;
}
div.profileInputMarginRight {
    margin-right:30px
}
div.includedInPlan{
    margin-left: 60px
}
.innerBodyMap{
    margin-left: 30px 
}
.notesListMain .listContainer{
    margin-right:25px;
    min-width: 200px
}
.formSelections{
    width: 30%; display: inline-block
}
.react-flow__attribution{
    display: none
}
.assessmentDescription .ratingBox{
   margin-left:15px
}
.genericAssessmentField{
    margin-top: 22px;
    margin-bottom: 15px
}
.bodyMapContainer .innerBodyMap{
    margin-left: 30px;
}
.sigCanvas{
    background-color: #f1f1f1;
}